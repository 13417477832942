


.card {
    background-color: #ffffff13;
    color: white;
    border-color: transparent; 
}

.list-group-item {
    background-color: transparent;
    color: white;
    border-color: transparent;
}


#data-list-savoir {
    padding-left: 0;
}
