#cv-print{
    background-color: #444444;
    font-weight: normal;
}


.pdf-page {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    margin: 0 auto;
    color: rgb(27, 27, 27);
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
}

.pdf-header {
    padding: 0 0.2in;
    padding-top: 0.2in;
    margin-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
    font-weight: bold;
    color: rgb(5, 56, 151);
}

.pdf-body {
    margin-top: 0px;
    padding: 0 0.2in;
    font-size: small;
}

.pdf-footer {
    padding: 0.05in 0.2in;
    padding-bottom: 20px;
    padding-top: 10px;
    border-top: 1px solid #e5e5e5;
    text-align: left;
    color: #787878;
    font-size: 9px;
}

.pdf-block {
    margin-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
}

.pdf-element{
    padding: 0 0.2in;
}

.cv-title span h1 {
    font-size: 35px;
    color: rgb(5, 56, 151);
}


.coord {
    background-color: #dff1ff;
    border-style: solid;
    border-width: 0.5px;
    border-color: #f1f9ff;
    font-size: 10px;
}

.coord-link {
    border-style: solid;
    border-width: 0.5px;
    border-color: #f1f9ff;
    font-size: 11px;  
}

.coord-void {
    border-style: solid;
    border-width: 0.5px;
    border-color: #f1f9ff;
    font-size: 11px;
    height: 17px;
}

.mobility {
    margin-top: 2px;
    font-size: 10px!important;
    color: rgb(5, 56, 151);
}

.h3-resize {
    font-size: 1.1rem;
    font-weight: bold;
    color: rgb(5, 56, 151);
}

.card-cv {
    background-color: #b8b8b87a!important;
    color: black!important;
    border-color: transparent!important; 
}

.list-group-item-cv {
    padding-left: 0;
    font-size: 11px;
    background-color: #b8b8b87a!important;
    color: rgba(0, 0, 0, 0.842)!important;
    border-color: transparent!important;
}

.name-cv {
    color: grey!important;
}

.formation-cv {
    font-size: 10px;
}
.formation-desc-cv {
    font-size: 9px;
}

.badge-container-cv {
    min-width: 40px;
}

.badge-cv {
    margin-top: 1px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 8px;
    padding: 2px 4px;
}

.badge-pro {
    background-color: rgb(29, 170, 36)!important;
}

.project-content-cv{
    background-color: #b8b8b87a;
    border-radius: 8px;
}

.container-formation-line {
    border-bottom: 2px solid white;
}

.formation-year {
    min-width: 72px;
    background-color: #b8b8b87a;
}

.formation-title-content {
    background-color: #b8b8b87a;
    padding-left: 8px;
}

.formation-content {
    background-color: #b8b8b82f;
    padding-left: 8px;
}

.formation-end-content {
    width: 2px;
    background-color: #b8b8b87a;
}

.formation-title {
    font-size: 12px;
}

.savoir-etre-container {
    width: 285px;
    font-size: 11px;
}

#savoir-etre-content {
    border-radius: 8px;
}


/* Dimensions the proper PDF output */
.size-a4-paysage {
    width: 1217px;
    height: 860px;
}

.size-a4-portrait {
    width: 860px;
    height: 1217px;
}

.box {
    padding: 0.7em;
    background-color: rgba(12, 34, 53, 0.233);
    border: 1px solid rgba(20, 53, 80, 0.05);
    margin-bottom: 20px;
}

@media print {               
    /* @page port { size: portrait; margin: 0mm; } */
    .portrait { size: portrait; margin: 0mm; }

    /* @page land { size: landscape; margin: 0mm; } */
    .paysage { size: landscape; margin: 0mm; }               
}
