

.item .icon {
    min-height: 64px;
    max-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
    border: 2px solid transparent;
    font-size: 1.6em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-color: transparent;
    background: #181a1b;
}

.item {
    min-width: 64px;
    max-width: 64px;
}

.name {
    padding-top: 2px;
    font-size: 12px;
}