@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;600;800&display=swap');


h1 {
    font-family: 'Nunito', sans-serif;
    font-size: 110px;
    color: rgb(47, 162, 255);
}

h2 {
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}

h3 {
    font-family: 'Nunito', sans-serif;
}

#photo-profil {
    box-shadow: 0px 0px 30px 5px rgb(54, 54, 54);
    border-radius: 500px;
}

.home-intro-box {
    color: black;
    min-height: 100vh;
    position: relative;
    padding-top: 90px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

#home-d-name {
    font-size: 20px;
}

#home-d-job {
    font-size: 30px;
    margin-top: -0.8em
}

#home-d-job-blue {
    color: rgb(47, 162, 255);
    font-size: 30px;
}


#home-freelance {
    font-family: 'Nunito', sans-serif;
    display: block;
    font-weight: 500;
    color:rgb(225, 227, 231);
    background-color: rgba(24, 26, 27, 0.658);
    border-radius: 20px;
    box-shadow: -5px -5px 30px 5px rgba(54, 54, 54, 0.50);
}

#home-d-freelance {
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    color: rgb(72, 165, 247);
}

#home-d-text {
    font-size: 20px;
}