

footer {
    background-color: rgba(1, 1, 10, 0.719);
}

.btn-mention {
    color: white;
    background-color: transparent;
    border: none;
    font-size: 0.9em;
}
.btn-mention:hover {
    text-decoration: underline;
}